/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-18",
    versionChannel: "nightly",
    buildDate: "2023-06-18T00:19:35.526Z",
    commitHash: "7bbaeb2a61703c919d961ec6624fb531dbc89485",
};
